<template>
  <v-dialog
    v-if="modalData"
    v-model="modalData.dialog"
    width="90%"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ $t("MENU.MEDIA_LIBRARY") }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="modalData.dialog">
        <MediaBrowser @handleSelectMedia="handleSelectMedia" :config="config">
        </MediaBrowser>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn color="primary" @click="handleSaveModalForm">
          {{ $t("FORMS.select") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import MediaBrowser from "@/view/media_library/Browser.vue";

export default {
  name: "SelectMediaModalForm",
  props: ["modalData", "config"],
  components: { MediaBrowser },
  data() {
    return {
      loader: false,
      selectedFiles: [],
    };
  },
  watch: {},
  methods: {
    handleSelectMedia(selectedFiles, closeSelectMediaModal = null) {
      this.selectedFiles = selectedFiles;
      if(closeSelectMediaModal) {
          this.handleSaveModalForm();
      }
    },

    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      this.$emit("saveModalForm", this.selectedFiles);
    },
  },
};
</script>
