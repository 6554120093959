<template>
  <v-dialog
    v-model="customFieldInputData.dialog"
    max-width="550px"
    persistent
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="headline">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ $t("FORMS.edit") }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
            <v-form v-model="customInputsModalFormValid" ref="custom_inputs_modal_form">
                <v-row v-if="customFieldInputData">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-for="field in customFieldInputData.inputFields"
                      :key="field.name"
                    >          
                    <v-text-field
                      v-if="field.type == 'text' && file"
                      v-model="file[field.name]"
                      :label="$t(field.label)"
                      :rules="(typeof field.required != 'indefined' && field.required) ? requiredRules : false"
                    ></v-text-field>

                    <div v-if="field.type == 'datePicker' && file"> 
                      <!--<b-form-datepicker
                        v-if="field.type == 'datePicker' && file"
                        v-model="file[field.name]"
                        class="mb-2"
                        locale="hu"
                        required="(typeof field.required != 'indefined' && field.required) ? true : false"
                      ></b-form-datepicker>-->          
                      <v-menu
                        ref="file[field.name + '_picker']"
                        v-model="file[field.name + '_picker']"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="file[field.name]"
                            :label="$t(field.label)"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="(typeof field.required != 'indefined' && field.required) ? requiredRules : false"   
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="file[field.name]"
                          no-title
                          scrollable
                          locale="hu-HU"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="file[field.name + '_picker'] = false">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                      
                    </div>

                    <div v-if="field.type == 'timePicker' && file">
                        <!--
                        {{ $t(field.label) }}
                        <b-form-timepicker 
                            v-if="field.type == 'timePicker' && file"
                            v-model="file[field.name]" 
                            class="mb-2"
                            locale="hu"
                            :rules="(typeof field.required != 'indefined' && field.required) ? requiredRules : false"
                        ></b-form-timepicker>
                        -->
                        <v-menu
                            ref="file[field.name + '_picker']"
                            v-model="file[field.name + '_picker']"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="file[field.name]"
                                :label="$t(field.label)"
                                prepend-icon="mdi-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="(typeof field.required != 'indefined' && field.required) ? requiredRules : false"   
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="file[field.name + '_picker']"
                              v-model="file[field.name]"
                              format="24hr"
                              full-width
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="file[field.name + '_picker'] = false">OK</v-btn>
                            </v-time-picker>
                          </v-menu>
                    </div>

                  </v-col>
                </v-row>
            </v-form>  
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">{{
          $t("FORMS.cancel")
        }}</v-btn>
        <v-btn color="primary" @click="handleSaveModalForm">
          {{ $t("FORMS.save") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import SelectMediaModalForm from "@/view/media_library/SelectMediaModalForm.vue";
// import Swal from "sweetalert2";
// import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
// import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "CustomInputsModalDialog",
  props: ["customFieldInputData"],
  components: {
    // SelectMediaModalForm,
    // DeleteModalDialog,
    // VueEasyLightbox,
  },
  mixins: [customFieldMixins],
  data() {
    return {
      testField: "",
      // file: {},
      file: null,
      // files: [],
      mediaModalData: {
        dialog: false,
      },
      column_class: "col-6 col-md-3 col-lg-4 col-xl-6",
      customInputsModalFormValid: false,
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  // computed: {
  //   vue_easy_lightbox_imgs() {
  //     if (!this.files) {
  //       return [];
  //     }

  //     let previewArray = [];
  //     this.files.forEach((item) => {
  //       if (this.previewable_mime_types.includes(item.mime_type)) {
  //         previewArray.push({ src: this.getFile(item), title: item.filename });
  //       }
  //     });
  //     return previewArray;
  //   },
  // },
  // watch: {
  //   customFieldInputData: {
  //     deep: true,
  //     handler(value) {
  //       console.log("value !!!!!", value);
  //       if (value.file) {
  //         this.file = value.file;
  //         // this.files = value.file.files || [];
  //       }
  //     },
  //   },
  // },
  methods: {
    handleCloseModalForm() {
      this.$emit("handleCloseModalForm");
    },

    handleSaveModalForm() {
      // this.file.files = this.files;
      this.$refs.custom_inputs_modal_form.validate();
      if (this.customInputsModalFormValid) {
          this.$emit("handleSaveModalForm", this.file);
      }
    },

    // handleOpenMediaSelectModal() {
    //   this.mediaModalData.dialog = true;
    // },
    // handleCloseMediaModalForm() {
    //   this.mediaModalData.dialog = false;
    // },

    // handleInfoBox(file) {
    //   Swal.fire({
    //     title: `<strong>${this.$t("ALERT.file_info_title")}</strong>`,
    //     icon: "info",
    //     width: "600px",
    //     html:
    //       `<b>${this.$t("ALERT.created_at")}:</b> ` +
    //       file.created_at +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.name")}:</b> ` +
    //       file.name +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.mime_type")}:</b> ` +
    //       file.mime_type +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.path")}:</b>
    //       <a href="` +
    //       this.getFile(file) +
    //       `" target="_blank">` +
    //       this.getFile(file) +
    //       `</a>` +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.size")}:</b> ` +
    //       file.size +
    //       "<br>",
    //     showCloseButton: true,
    //     focusConfirm: false,
    //     confirmButtonText: "OK",
    //     confirmButtonColor: "#e33354",
    //     infoColor: "#e33354",
    //   });
    // },

    // showImg(index) {
    //   this.vue_easy_lightbox_index = index;
    //   this.vue_easy_lightbox_visible = true;
    // },
    // handleVueEasyLightboxHide() {
    //   this.vue_easy_lightbox_visible = false;
    // },
    // getFile(file) {
    //   return process.env.VUE_APP_BACKEND_URL + "/" + file.path;
    // },
    // deleteItem(item) {
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // handleDeleteItemConfirm() {
    //   this.files = this.files.filter(
    //     (value) => value.path != this.editedItem.path
    //   );
    //   this.dialogDelete = false;
    // },

    // closeDelete() {
    //   this.editedItem = null;
    //   this.dialogDelete = false;
    // },

    // handleSaveMediaModalForm(selectedFiles) {
    //   selectedFiles.forEach((item) => {
    //     let add = true;
    //     this.files.forEach((cFV) => {
    //       if (cFV.path == item.path) {
    //         add = false;
    //       }
    //     });
    //     if (add) {
    //       this.files.push(item);
    //     }
    //   });
    //   // // this.handleChangeValue(this.customFieldValue);
    //   this.handleCloseMediaModalForm();
    // },
  },

  mounted() {
    this.file = this.customFieldInputData.file;
  },
};
</script>
